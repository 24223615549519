@import 'theme/_colours';
@import './mixins/compass-helpers';

.error-panel {
  margin-top: 20px;
}

.error-container {
  z-index: 9999;
  background: white !important;
  overflow: auto;
  width: 100%;
  height: 100%;
  text-align: center;
  border: unset !important;
  @media (max-height: 767px) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 20px;
  }
}

.error-header {
  padding-top: 7rem;
}

.error-http-code {
  font-size: 72px;
  font-weight: bold;
  letter-spacing: 0.66px;
  text-align: center;
  color: #27b3c1;
  margin-bottom: 30px;
  @media (max-height: 800px) {
    font-size: 10vh;
    height: 10vh;
    line-height: normal;
    margin-bottom: 10px;
  }
}

.error-code {
  word-wrap: break-word;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.48px;
  text-align: center;
  color: #666666;
  background-color: #f2f2f2;
  padding: 40px;
  margin: 0px auto 30px;
  width: 940px;
  max-width: 90%;
}

.error-title {
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0.24px;
  text-align: center;
  color: #323232;
  margin-bottom: 30px;
}

.error-message {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.48px;
  text-align: center;
  color: #666666;
  width: 940px;
  max-width: 90%;
  margin: 0 auto 30px;
}

.error-return-link {
  padding: 12px 16px;
  margin-bottom: 50px;
}

.error-image {
  margin: auto;
  margin-top: 160px;
  margin-bottom: 50px;
  object-fit: contain;
  max-width: 100%;
  @media (max-height: 900px) {
    margin: 20px auto !important;
    height: 35vh;
  }
  @media (max-height: 640px) {
    display: none;
  }
}

.oauth-error {
  .error-return-link {
    display: none;
  }
}
