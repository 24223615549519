@import 'palette';

/*
 * Theme
 */
$navbar-bg: #332913;
$body-bg: #32d6bc;
$link-yellow: #ffce00;
$border-grey: #edebe9;
$text-dark: #222222;
$text-white: #ffffff;

$border-light-grey: #cccccc;
$grey-standard: #ddd;
$grey-light: #faf8f0;
$grey-light-alt: #fafafa;
$grey-light-alt-2: #aaaaaa;
$grey-light-alt-3: #b4b4b4;
$grey-light-alt-4: #fcfcfc;
$grey-light-alt-5: #ebebeb;
$grey-light-alt-6: #c0bebe;
$grey-light-alt-7: #d8d8d8;
$grey-light-alt-8: #e2e2e2;

$grey-medium: #eeeeee;
$grey: #c9c2bd;
$grey-medium-dark: #999999;
$grey-dark: #444444;
$grey-dark-alt: #414141;
$grey-dark-alt-2: #666666;
$grey-dark-alt-3: #7d7c7c;
$grey-dark-alt-4: #6d7278;
$grey-dark-alt-5: #888787;
$grey-dark-alt-6: #8b959e;
$grey-dark-alt-7: #858585;

$control-hover-background: $grey-standard;

/**
 * Form
 */
$form-bg-grey: #f5f5f5;
$content-main-bg: #f2f2f2;
$text-form-control: $grey-dark-alt-2;

$brown-light: #c2a27b;
$brown-border: #ba9c77;
$brown: #7a6550;
$brown-dark: #42332a;

$blue-light: #a8e0e6;
$blue: #27b3c1;
$blue-highlight: #17b3c2;
$blue-highlight-alt: #d8f2f5;
$blue-dark: #218590;
$highlight: #66afe9;

$green-light: #6add74;
$green-med: #72da7f;
$green: #6add74;

$red-light: #fdbd99;
$red: #ff0e0e;
$red-dark: #bd460a;

$yellow-light: #fff9e5;
$yellow-med: #ffeb99;
$yellow: #ffce00;
$orange: #dc9300;
$mailchimp-orange: #e85c41;

$planlock-overlay: rgba(255, 255, 255, 0.75);

/**
 * External
 */

// Integrations
$mailchimp-grey: #f9f9f9;
$intercom-blue: #008fe8;
$zapier-red: #ff4a00;

// Social
$facebook-blue: #3b5998;
$twitter-blue: #55acee;
$linkedin-blue: #0077b5;
$reddit-orange: #ff5700;
$google-plus-red: #dd4b39;

.facebook-blue {
  color: $facebook-blue;
}

.twitter-blue {
  color: $twitter-blue;
}

.linkedin-blue {
  color: $linkedin-blue;
}

.reddit-orange {
  color: $reddit-orange;
}

.mail-yellow {
  color: $yellow;
}
